import { render } from "./WebChat.vue?vue&type=template&id=41021982"
import script from "./WebChat.vue?vue&type=script&lang=js"
export * from "./WebChat.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "41021982"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('41021982', script)) {
    api.reload('41021982', script)
  }
  
  module.hot.accept("./WebChat.vue?vue&type=template&id=41021982", () => {
    api.rerender('41021982', render)
  })

}

script.__file = "src/views/WebChat.vue"

export default script