<template>
  <WebChatComponent />
</template>

<script>
import WebChatComponent from "../components/WebChatComponent.vue";
export default {
  name: "Web Chat",
  components: {
    WebChatComponent,
  },
};
</script>
