<template>
  <p
    id="notice"
    class="absolute border border-green-600 text-green-600 bg-white px-4 py-1 rounded left-1/2 top-1/2"
  >
    Copied.
  </p>
  <div class="py-4">
    <BusinessHeader />
    <div
      class="relative my-2 pl-6 pr-8 py-4 text-sm border border-gray-300 bg-white"
    >
      <p class="font-mono text-xs">{{ webchatScript }}</p>
      <svg
        @click="copyToClipboard"
        class="absolute top-1/2 transform -translate-y-1/2 right-2 h-5 w-5 text-green-800 group-hover:text-gray-300"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="{2}"
          d="M8 5H6a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2v-1M8 5a2 2 0 002 2h2a2 2 0 002-2M8 5a2 2 0 012-2h2a2 2 0 012 2m0 0h2a2 2 0 012 2v3m2 4H10m0 0l3-3m-3 3l3 3"
        />
      </svg>
      <input
        class="w-full"
        type="hidden"
        id="copy-text"
        :value="webchatScript"
      />
    </div>
  </div>
</template>

<script>
import BusinessHeader from "./BusinessHeader";
// import axios from "axios";
const firebase = require("../firebaseConfig");
export default {
  name: "WebChatComponent",
  components: {
    BusinessHeader,
  },
  data: () => ({
    business: {},
    domain: process.env.VUE_APP_DOMAIN,
    webchatScript: "",
  }),
  mounted() {
    this.business.id = this.$route.params.business_id;
    this.webchatScript =
      `<scr` +
      `ipt type='text/javascript' src='https://${this.domain}/web-chat/loader.min.js' data-businessId='${this.business.id}'></scr` +
      `ipt>`;
  },
  methods: {
    copyToClipboard() {
      const notice = document.querySelector("#notice");
      let textToCopy = document.querySelector("#copy-text");
      textToCopy.setAttribute("type", "text");
      textToCopy.select();
      try {
        document.execCommand("copy");
        notice.classList.add("copied");
        setTimeout(() => {
          notice.classList.remove("copied");
        }, 2000);
      } catch (err) {
        console.log(err);
      }
      textToCopy.setAttribute("type", "hidden");
      window.getSelection().removeAllRanges();
    },
    async getBusinessInfo() {
      await firebase.db
        .collection("businesses")
        .doc(this.business.id)
        .onSnapshot((doc) => {
          this.business = {
            id: doc.id,
            ...doc.data(),
          };
        });
    },
  },
};
</script>

<style scoped>
#notice {
  opacity: 0;
  transition: opacity 600ms ease;
  pointer-events: none;
}
#notice.copied {
  opacity: 1;
}
</style>
